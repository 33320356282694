import React from "react";
import {
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import "../components/Footer.css";
import { Link } from "react-router-dom";
import { ImYoutube2 } from "react-icons/im";

const Footer = ({ setClick, setState }) => {
  const year = new Date().getFullYear();

  const closeSubmenu = () => {
    setState(false);
  };

  return (
    <footer onMouseOver={closeSubmenu}>
      <div className="footer-container-grid container">
        <nav className="nav-col">
          <p className="footer-heading">Branches</p>
          <ul className="footer-nav">
            <li>
              <a
                className="footer-link"
                href="https://goo.gl/maps/hDbhvtcvuBQK49sP6"
                target="_blank"
              >
                Nerul
              </a>
            </li>
            <li>
              <a
                className="footer-link"
                href="https://goo.gl/maps/mGY5rKt2gdmpeNPBA"
                target="_blank"
              >
                Kankavali
              </a>
            </li>
          </ul>
        </nav>

        <nav className="nav-col">
          <p className="footer-heading">Non-IT</p>
          <ul className="footer-nav">
            <li>
              <a
                href="https://digitalmarketing.incretech.in/"
                className="footer-link"
              >
                Digital Marketing
              </a>
            </li>
            <li>
              <a
                href="https://graphicdesign.incretech.in/"
                className="footer-link"
              >
                Graphic Design
              </a>
            </li>
          </ul>
        </nav>

        <nav className="nav-col">
          <p className="footer-heading">IT</p>
          <ul className="footer-nav">
            <li>
              <a
                href="https://computerservices.incretech.in/"
                className="footer-link"
              >
                PC & Laptops
              </a>
            </li>
            <li>
              <a
                href="https://cctvservices.incretech.in/"
                className="footer-link"
              >
                CCTV
              </a>
            </li>
            <li>
              <a
                href="https://biometricservices.incretech.in/"
                Link
                className="footer-link"
              >
                Biometric & Access Control{" "}
              </a>
            </li>
            <li>
              <a href="https://intercom.incretech.in/" className="footer-link">
                EPABX & Intercom
              </a>
            </li>
            <li>
              {" "}
              <a
                href="https://networksolutions.incretech.in/"
                className="footer-link"
              >
                Networking
              </a>
            </li>
            <li>
              {" "}
              <a
                href="https://solarinstallation.incretech.in/"
                className="footer-link"
              >
                Solar Energy
              </a>
            </li>
            <li>
              {" "}
              <a
                href="https://electricalservices.incretech.in/"
                className="footer-link"
              >
                Electrical Services{" "}
              </a>
            </li>

            <li>
              {" "}
              <a
                href="https://mobilesignalbooster.incretech.in/"
                className="footer-link"
              >
                Mobile Signal Boosters
              </a>
            </li>

            <li>
              <a
                href="https://airconditionerservices.incretech.in/"
                className="footer-link"
              >
                Air Conditioner{" "}
              </a>
            </li>
            <li>
              {" "}
              <a
                href="https://wifiextensions.incretech.in/"
                className="footer-link"
              >
                Wi-Fi Extensions
              </a>
            </li>
            <li>
              {" "}
              <a
                href="https://videodoorphoneservices.incretech.in/"
                className="footer-link"
              >
                Video Door Phone
              </a>
            </li>
            <li>
              <a href="https//webdesign.incretech.in/" className="footer-link">
                Website Development
              </a>
            </li>

            <li>
              <a
                href="https://applicationdeveloper.incretech.in/"
                className="footer-link"
              >
                Application Development
              </a>{" "}
            </li>
          </ul>
        </nav>

        <nav className="nav-col">
          <p className="footer-heading">Company</p>
          <ul className="footer-nav">
            <li>
              <a className="footer-link" href="https://incretech.in/about">
                About
              </a>
            </li>
            <li>
              <a className="footer-link" href="https://incretech.in/career">
                Career
              </a>
            </li>
            <li>
              <a
                className="footer-link"
                href="https://incretech.in/privacy-policy"
              >
                Privacy Policy
              </a>
            </li>

            <li>
              <a
                className="footer-link"
                href="https://incretech.in/terms-and-conditions"
              >
                Terms and Conditions
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div className="footer-container container">
        <p className="footer-text" style={{ textAlign: "center" }}>
          © Copyright {year}, INCRETECH Group, All rights reserved.
        </p>
        <div className="social-media-handles-footer ">
          <ul>
            <li>
              <a
                href="https://www.instagram.com/incretech_/"
                target="_blank"
                className="social-link"
              >
                <FaInstagram className="instagram" />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/incretech" target="_blank">
                <FaFacebookF className="facebook" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/incretech-private-limited/"
                target="_blank"
              >
                <FaLinkedinIn className="linkedin" />
              </a>
            </li>

            <li>
              <a href="https://twitter.com/Incretech_" target="_blank">
                <FaTwitter className="twitter" />
              </a>
            </li>

            <li>
              <a href="https://www.youtube.com/@incretech" target="_blank">
                <FaYoutube className="twitter" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
